<template>
    <v-container>
        <v-overlay :absolute="true" :value="noOrgInfo">
            <org-overlay></org-overlay>
        </v-overlay>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <div class="category-container-register">
            <div class="container-bg" style="width: 85%; margin: auto">
                <div class="container-fg">
                    <div style="display: flex; flex-direction: row">
                        <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
                        <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">PRODUCT REGISTRATION</div>
                    </div>
                    <v-divider style="margin-bottom: 1%; border-color: gray" />
                    <div>
                        <v-card id="product-registration" elevation="0" dark color="transparent">
                            <v-tabs class="request-tab-top" dark>
                                <v-tab dark><div class="text-h7 nav-font">Register Serial</div></v-tab>
                                <v-tab dark><div class="text-h7 nav-font">Upload File</div></v-tab>
                                <v-tab dark><div class="text-h7 nav-font">List Registered Products</div></v-tab>

                                <v-tab-item style="background-color: transparent !important">
                                    <v-card elevation="0" dark color="transparent">
                                        <div style="margin-top: 15px; margin-bottom: 15px">
                                            <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                                                Register Serial Number
                                            </h1>
                                            <p>Please enter your serial number and press register.</p>
                                        </div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field
                                                    v-model="serialNumber"
                                                    outlined
                                                    dark
                                                    label="Serial Number"
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field
                                                    v-model="assetTag"
                                                    outlined
                                                    dark
                                                    label="Asset Tag"
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field
                                                    v-model="location"
                                                    outlined
                                                    dark
                                                    label="Location"
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-btn
                                                    color="primary"
                                                    outlined
                                                    dark
                                                    x-large
                                                    :disabled="!serialNumber"
                                                    @click="submitSerial"
                                                    >Register</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                        <v-divider style="margin-bottom: 1%; margin-top: 45px; border-color: gray" />
                                        <div>
                                            <h1>{{ scannedItem }}</h1>
                                        </div>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card elevation="0" dark color="transparent">
                                        <div style="margin-top: 15px; margin-bottom: 15px">
                                            <h1 style="font-size: 36px; letter-spacing: -0.08rem"> Upload File </h1>
                                            <p
                                                >Please click upload to select and submit an excel file containing the
                                                serials.</p
                                            >
                                        </div>

                                        <v-col cols="6" style="padding: 0px">
                                            <v-btn
                                                outlined
                                                tile
                                                block
                                                x-large
                                                color="primary"
                                                @click="$refs.serialUpload.click()"
                                            >
                                                Upload Serials
                                            </v-btn>
                                            <input
                                                v-show="false"
                                                ref="serialUpload"
                                                type="file"
                                                accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsb"
                                                @change="
                                                    (e) => {
                                                        uploadSerialNumbers(e.target.files[0])
                                                        $refs.serialUpload.value = ``
                                                    }
                                                "
                                            />
                                            <br />

                                            <v-btn outlined tile block x-large @click="downloadFormatExample"
                                                >Download format example</v-btn
                                            >
                                        </v-col>
                                        <v-divider style="margin-bottom: 1%; margin-top: 45px; border-color: gray" />
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item style="background-color: transparent !important">
                                    <v-card elevation="0" dark style="margin-top: 25px" color="transparent">
                                        <div style="margin-top: 15px; margin-bottom: 15px">
                                            <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                                                List Registered Products
                                            </h1>
                                            <p
                                                >Select a model to see currently registered serials. Click export to
                                                export the current list of serials.</p
                                            >
                                        </div>
                                        <div>
                                            <v-row>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        id="data-text-field"
                                                        v-model="search"
                                                        append-icon="mdi-magnify"
                                                        label="Search Serial"
                                                        single-line
                                                        dense
                                                        hide-details
                                                        clearable
                                                        outlined
                                                        dark
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-select
                                                        v-model="selectedModel"
                                                        dark
                                                        outlined
                                                        dense
                                                        item-value="model"
                                                        item-text="model"
                                                        label="Select Model"
                                                        :items="querySMModel"
                                                        @change="listRegistered($event)"
                                                    >
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-btn
                                                        style="margin-left: 15px"
                                                        color="primary"
                                                        elevation="0"
                                                        outlined
                                                        @click="exportCurrent"
                                                        >Export Current List</v-btn
                                                    >
                                                    <v-btn
                                                        style="margin-left: 15px"
                                                        color="primary"
                                                        elevation="0"
                                                        text
                                                        @click="viewAll"
                                                        >View All</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-divider style="margin-bottom: 1%; border-color: gray" />
                                        <v-data-table
                                            style="background-color: transparent"
                                            dark
                                            :headers="optionHeaders"
                                            :search="search"
                                            disable-sort
                                            :no-data-text="noItemsMessage"
                                            :items="registeredSns"
                                            :loading="$apollo.loading"
                                        >
                                            <template v-slot:[`item.registerDate`]="{ item }">
                                                {{ new Date(item.registerDate).toLocaleString() }}
                                            </template>
                                            <template v-slot:[`item.delete`]="{ item }">
                                                <v-btn color="primary" elevation="0" text @click="deleteSerial(item.sn)"
                                                    >Delete</v-btn
                                                >
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { navLinks } from '../navigation.js'
import gql from 'graphql-tag'
import { downloadJsonAsExcel, processExcel } from '@/utils/excel'
import serialsExample from './serials_example.js'
import OrgOverlay from '../../../components/no_org_overlay'

export default {
    metaInfo: { title: `Your Account`, subTitle: `Account Settings` },
    name: `ProductRegistration`,
    components: { OrgOverlay },
    apollo: {
        queryOrg: {
            query: gql`
                query QueryOrg {
                    queryOrg {
                        gssOrgId
                        orgId
                        orgName
                    }
                }
            `,
            update(data) {
                if (!data.queryOrg) {
                    this.noOrgInfo = true
                }
            }
        },
        querySMModel: {
            query: gql`
                query querySMModel {
                    querySMModel {
                        model
                        iwQty
                        oowQty
                    }
                }
            `
        },
        queryRegisteredSNList: {
            query: gql`
                query QueryRegisteredSNList($model: String) {
                    queryRegisteredSNList(model: $model) {
                        sn
                        assetTag
                        location
                        inwarranty
                        registerDate
                        model
                    }
                }
            `,
            update(data) {
                this.registeredSns = data.queryRegisteredSNList
            }
        }
    },
    data() {
        return {
            links: navLinks,
            noOrgInfo: false,
            serialsExample,
            serialNumber: '',
            assetTag: '',
            location: '',
            snackbar: false,
            snackbarMessage: '',
            models: [],
            selectedModel: '',
            registeredSns: [],
            search: '',
            noItemsMessage: 'No registered serials found.',
            fileInput: null,
            uploadedSns: [],
            scannedItem: '',
            modelsList: [],
            optionHeaders: [
                {
                    text: 'Serial #',
                    value: 'sn',
                    align: 'left'
                },
                {
                    text: 'Model',
                    value: 'model'
                },
                {
                    text: 'Asset Tag',
                    value: 'assetTag'
                },
                {
                    text: 'Location',
                    value: 'location',
                    align: 'left'
                },
                {
                    text: 'In Warranty',
                    value: 'inwarranty'
                },
                {
                    text: 'Register Date',
                    value: 'registerDate'
                },
                {
                    text: '',
                    value: 'delete'
                }
            ]
        }
    },
    methods: {
        downloadFormatExample() {
            const example = [
                {
                    SERIAL_NUMBER: null,
                    ASSET_TAG: null,
                    LOCATION: null
                }
            ]
            downloadJsonAsExcel(`SerialsUpload`, `Serials`, example)
        },
        viewAll() {
            this.selectedModel = ''
            this.listRegistered(null)
        },
        scannerTest(serial) {
            this.scannedItem = serial
        },
        deleteSerial(serial) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation DeregisterSNs($snList: [String!]!) {
                            deregisterSNs(snList: $snList)
                        }
                    `,
                    variables: {
                        snList: serial
                    }
                })
                .then((data) => {
                    if (data.data.deregisterSNs === 'success') {
                        this.snackbar = true
                        this.snackbarMessage = `Serial #` + serial + ` successfully deleted`
                        this.$apollo.queries.querySMModel.fetchMore({})
                        this.$apollo.queries.queryRegisteredSNList.fetchMore({})
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = 'Error deleting serial #:' + serial
                    }
                })
                .catch((error) => {
                    this.snackbar = true
                    this.snackbarMessage = 'Error deleting serial #:' + serial + ': ' + error
                })
        },
        submitSerial() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RegisterSNs($snList: [SNRegistrationEntry!]!) {
                            registerSNs(snList: $snList)
                        }
                    `,
                    variables: {
                        snList: [
                            {
                                sn: this.serialNumber.toUpperCase().trim(),
                                assetTag: this.assetTag.trim(),
                                location: this.location.trim()
                            }
                        ]
                    }
                })
                .then((data) => {
                    if (data.data.registerSNs === 'success') {
                        this.snackbar = true
                        this.snackbarMessage = `Serial #` + this.serialNumber + ` successfully registered`
                        this.serialNumber = ''
                        this.assetTag = ''
                        this.location = ''
                        this.$apollo.queries.querySMModel.fetchMore({})
                        this.$apollo.queries.queryRegisteredSNList.fetchMore({})
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = `Error registering serial: ` + data.data.registerSNs
                    }
                })
                .catch((error) => {
                    this.snackbar = true
                    this.snackbarMessage = `Error registering serial ${this.serialNumber}: ` + error
                })
        },
        scannedSerial(serial) {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation RegisterSNs($snList: [SNRegistrationEntry!]!) {
                            registerSNs(snList: $snList)
                        }
                    `,
                    variables: {
                        snList: [
                            {
                                sn: serial
                            }
                        ]
                    }
                })
                .then((data) => {
                    if (data.data.registerSNs === 'success') {
                        this.snackbar = true
                        this.snackbarMessage = `Serial #` + serial + ` successfully registered`
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = 'Error registering serial #:' + serial
                    }
                })
        },
        listRegistered(model) {
            this.registeredSns = []
            this.$apollo
                .query({
                    query: gql`
                        query QueryRegisteredSNList($model: String) {
                            queryRegisteredSNList(model: $model) {
                                sn
                                assetTag
                                location
                                inwarranty
                                registerDate
                                model
                            }
                        }
                    `,
                    variables: {
                        model: model
                    }
                })
                .then((data) => {
                    this.registeredSns = data.data.queryRegisteredSNList
                })
        },
        exportCurrent() {
            this.$apollo
                .query({
                    query: gql`
                        query QueryRegisteredSNList($model: String) {
                            queryRegisteredSNList(model: $model) {
                                sn
                                assetTag
                                location
                                inwarranty
                                registerDate
                                model
                            }
                        }
                    `,
                    variables: {
                        model: this.selectedModel
                    }
                })
                .then((data) => {
                    downloadJsonAsExcel(
                        `${this.selectedModel}` + ` Registered Serials`,
                        `${this.selectedModel}` + ` Registered Serials`,
                        data.data.queryRegisteredSNList.map((e) => ({
                            Serial: e.sn,
                            Model: e.model,
                            AssetTag: e.assetTag,
                            Location: e.location,
                            InWarranty: e.inwarranty,
                            RegisterDate: new Date(e.registerDate).toLocaleString()
                        }))
                    )
                    this.registeredSns = data.data.queryRegisteredSNList
                })
        },
        uploadSerialNumbers(file) {
            if (file) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const processedExcelJson = JSON.parse(processExcel(e.target.result))

                    const inner = processedExcelJson[Object.keys(processedExcelJson)[0]]

                    inner.splice(0, 1)

                    const serials = inner.map((e) => ({
                        sn: e[0].toUpperCase(),
                        assetTag: e[1] ? e[1].toString() : ``,
                        location: e[2] ? e[2].toString() : ``
                    }))

                    this.uploadedSns = serials

                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation RegisterSNs($snList: [SNRegistrationEntry!]!) {
                                    registerSNs(snList: $snList)
                                }
                            `,
                            variables: {
                                snList: this.uploadedSns
                            }
                        })
                        .then((data) => {
                            if (data.data.registerSNs === 'success') {
                                this.snackbar = true
                                this.snackbarMessage = this.uploadedSns.length + ` serials successfully registered`
                                this.uploadedSns = []
                            } else {
                                this.snackbar = true
                                this.snackbarMessage = 'Error registering serials: ' + data.data.registerSNs
                                this.uploadedSns = []
                            }
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarMessage = 'Error registering serials'
                            this.uploadedSns = []
                        })
                }
                reader.readAsBinaryString(file)
            }
        }
    }
}
</script>

<style>
#product-registration .v-slide-group__content {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0.096));
    border-radius: 6px;
}
.v-tabs .request-tab-top .theme--dark {
    background-color: transparent !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: transparent;
}
.category-container-register .theme--light.v-tabs-items {
    background-color: transparent !important;
}
</style>
